<template>
  <div id="main-wrapper" class="inner-pages why-page legal-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Legal Documents</h2>
        <p>We proudly embrace honesty and transparency at AAAFx</p>
      </div>
    </div>
    <div class="section why-bel">
      <div class="wrapper">
        <div class="title-holder t-center wow fadeIn">
          <h5>
            We recommend you to read and understand all the necessary<br />legal
            documents before starting your trading journey with us.
          </h5>
        </div>
      </div>
    </div>
    <div class="section docu-list t-center wow fadeIn">
      <div class="wrapper">
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Privacy Policy</h3>
            <a target="_blank" href="assets/docs/Privacy Policy.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Risk Disclaimer</h3>
            <a target="_blank" href="assets/docs/Risk Disclaimer.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Retail Client Agreement</h3>
            <a target="_blank" href="assets/docs/Retail Client Agreement.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Refund Policy</h3>
            <a target="_blank" href="assets/docs/Refund Policy.pdf">view</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Execution Policy</h3>
            <a target="_blank" href="assets/docs/Execution Policy.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Conflicts Of Interest Policy</h3>
            <a
              target="_blank"
              href="assets/docs/Conflicts Of Interest Policy.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Client Assets</h3>
            <a target="_blank" href="assets/docs/Client Assets.pdf">view</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>Investor Compensation Scheme</h3>
            <a
              target="_blank"
              href="assets/docs/Investor Compensation Scheme.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>KEY INFORMATION DOCUMENT – CFDS ON COMMODITIES</h3>
            <a target="_blank" href="assets/docs/Key_Information_Document_CFD_COMMODITIES.pdf"
              >View</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>KEY INFORMATION DOCUMENT–CFDS ON INDICES</h3>
            <a target="_blank" href="assets/docs/Key_Information_Document_CFD_INDICES.pdf">View</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>KEY INFORMATION DOCUMENT–CFD’s ON SHARES</h3>
            <a target="_blank" href="assets/docs/Key_Information_Document_CFD_SHARES.pdf">view</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>KEY INFORMATION DOCUMENT–CFDS ON CURRENCY PAIRS</h3>
            <a target="_blank" href="assets/docs/Key_Information_Document_CFD_FOREX.pdf"
              >view</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img src="assets/images/pdf-ico.webp" alt="AAAFx" title="AAAFx" width="45" height="49" class="fluid-img" />
          </div>
          <div class="details">
            <h3>MiFID ΙΙ Directive Basics</h3>
            <a target="_blank" href="assets/docs/MiFID ΙΙ Directive Basics.pdf"
              >view</a
            >
          </div>
        </div>
        <!-- <div class="docu-holder">
          <div class="icon-holder"><img src="assets/images/pdf-ico.webp" alt="" /></div>
          <div class="details">
            <h3>Privacy Policy</h3><a target="_blank" href="assets/docs/Privacy Policy.pdf"
              >view</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
